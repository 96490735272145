<template>
    <div class="base-container">
        <div class="main-wrapper">
            <div class="top_bar">
                <p class="bar">测算报告 / <span>预览报告</span></p>
                <div class="back" @click="back">返回上级</div>
            </div>
            <div class="text_box">
                <p class="title"><i class="blue">{{reportData.areaName}}</i>房地产项目清算成果测算报告</p>
                <p >为保障房地产行业税负公平，促进行业良性发展；同时理清行业存量税收，保障地方一般预算收入，我们依据协同房地产行业大数据、土地交易信息、互联网公布的房产销售信息、建设部公布单方造价信息等对<i class="blue">{{reportData.areaName}}</i>辖区内存量房地产项目进行了税收存量的测算，因房地产项目个性化因素、互联网数据精准度因素等导致测算结果存在一定误差，该测算结果仅供领导决策参考。</p>
                <div>一、测算结果说明</div>
                <p><i class="blue">{{reportData.yearStart}}</i>年至<i class="blue">{{reportData.yearEnd}}</i>年，<i class="blue">{{reportData.areaName}}</i>共出让地块<i class="blue">{{reportData.landNum}}</i>块(土地出让面积1万平方米以上的)，其中：纯运营类房地产开发类地块<i class="blue">{{reportData.realEstateLandNum}}</i>块，土地面积<i class="blue">{{reportData.landArea}}</i>万平方米，涉及交易金额<i class="blue">{{reportData.landAmount}}</i>亿元。</p>
                <p>运用协同房地产行业大数据进行测算，该部分房地产项目清算后，预计补缴税款项目<i class="blue">{{reportData.paymentNum}}</i>个，退还税款项目<i class="blue">{{reportData.refundNum}}</i>个，预计可新增入库税款约<i class="blue">{{reportData.taxMoney}}</i>亿元；</p>
                <div>二、测算方法说明</div>
                <p>以国土部门公示的中国土地市场网的区域<i class="blue">{{reportData.yearStart}}</i>-<i class="blue">{{reportData.yearEnd}}</i>年土地交易数据为基础，清理、筛选有效的市场行为房地产开发地块，获取地块建设指标与经济指标。</p>
                <p>以房管部门、房产交易网、房天下、安居客、搜狐焦点等为数据源，广泛获取、校准区域房价销售信息。</p>
                <p>以住建部门公示的区域建筑材料市场价格信息、询价无忧网、广材网、造价通等为数据源，获取、校准地区建筑成本信息。</p>
                <p>以上数据为基础，应用协同大数据的房地产项目建设模型、地块投资收益测算模型、建筑工程成本测算模型、房地产项目税负测算模型、土地成本和开发成本均采用建筑面积法分摊成本、土地成本在计容面积间分摊，开发成本在总建筑面积之间分摊、地下面积默认为无产权不参与清算并预留成本等对区域的房地产进行税负测算，得出区域测算结果。</p>
                <div>三、数据情况说明</div>
                <p>土地相关数据采集时，土地市场网交易信息与其他政府部门公示结果有差异的，以土地市场网信息为基准，其他信息进行校准。</p>
                <p>销售数据采集时，以互联网获取该区域的各年度房产销售均价为基准，以住建公布的销售信息进行校准。</p>
                <p>建筑成本数据采集时，因未获取到历史年度的当地造价、当地建筑材料价，测算建筑成本采用<i class="blue">{{reportData.costSource2}}</i>其他地市公布的建筑成本。</p>
                <p>因政府调控的棚改、安居等房地产开发项目范围与调控方式的相关数据获取困难，所以税款测算按照市场的公允价值进行测算，可能与实际情况存在误差。</p>
                <p>因测算房地产项目个性化差异较大，互联网获取的数据的精准度等因素，测算的结果存在一定误差，结果仅供参考，具体测算信息见附表一。</p>
                <div>附表一、区域地块税负测算结果明细表</div>
                <el-table ref="table" :data="reportData.landList" border stripe :header-cell-style="{background:'#F6F6F6',color:'#333333'}">
                     <el-table-column type="index" label="序号" width="40" align="center"></el-table-column>
                    <el-table-column prop="landHolder" label="土地使用权人"  width="100" align="left"></el-table-column>
                    <el-table-column prop="landSupplyMethod" label="出让方式" width="50"  align="left"></el-table-column>
                    <el-table-column prop="projectAddress" label="项目地址"  width="120" align="left"></el-table-column>
                    <el-table-column prop="contractSigningDate" label="合同签订日期"  width="105"  align="right"></el-table-column>
                    <el-table-column prop="areaSize" :formatter="stateFormat" label="土地面积(㎡)" width="105"  align="right"></el-table-column>
                    <el-table-column prop="salePrice" :formatter="stateFormat" label="土地价格(元)" width="105"  align="right"></el-table-column>
                    <el-table-column prop="plotRatioUp" label="容积率"  width="70"  align="right"></el-table-column>
                    <el-table-column prop="shouldTax" :formatter="stateFormat" width="105" label="预测应缴税款"  align="right"></el-table-column>
                    <el-table-column prop="remissionTax" :formatter="stateFormat" width="105" label="预测减免税款"  align="right"></el-table-column>
                    <el-table-column prop="paidTax" :formatter="stateFormat" width="105" label="预测已缴税款"  align="right"></el-table-column>
                    <el-table-column prop="refundTax" :formatter="stateFormat" label="预测应补(退)税款"  align="right"></el-table-column>
                </el-table>
            </div>
        </div>
    </div>
    

</template>

<script>
import {getStore} from "@/util/store";
export default {
    data() {
        return {
            reportData: {},
        }
    },
    created(){
        this.reportData = getStore('reportData');
    },
    mounted() {
    },
    methods:{
        back(){
            this.$router.push({path: "/industryAnalysis"});
        },
        stateFormat (row, column, cellValue) {
            cellValue += ''
            if (!cellValue.includes('.')) cellValue += '.'
            return cellValue.replace(/(\d)(?=(\d{3})+\.)/g, function ($0, $1) {
            return $1 + ','
            }).replace(/\.$/, '')
        },
      
    }
}
</script>

<style scoped  lang="less">
// ::-webkit-scrollbar {
//     width: 0;
// }
.top_bar{
    height: 52px;
    line-height: 52px;
    overflow: hidden;
    font-size: 14px;
    position: relative;
    .bar{
        color: #969799;
        span{
            color: #646566;
        }
    }
    .back{
        position: absolute;
        right: 0;
        top: 0;
        color: #185BCE;
        cursor: pointer;
    }
    
}
.blue{
   color: #185BCE; 
   font-style:normal
}
.text_box{
    background: #fff;
    padding: 24px;
    box-sizing: border-box;
    line-height: 20px;
    font-size: 14px;
    color: #333;
    .title{
        text-align: center;
        font-size: 22px;
        font-weight: 600;
        color: #000;
        text-indent: 0em !important;
        margin-bottom: 24px;
    }
    p{
        margin: 0;
        padding: 0;
        text-indent: 2em;
    }
    div{
        font-size: 14px;
        margin-top: 24px;
        font-weight: 600;
    }
}
</style>